export const RunPayrollData = [
  {
    __typename: "core_employee",
    payroll_info: {
      __typename: "core_payroll",
      metadata: {
        phone: "+2349123526888",
        staff_id: "1",
        bank_code: "GTB-176",
        account_number: "124676144",
        monthly_net_salary: "36000",
        monthly_gross_salary: "3000",
      },
      monthly_gross_salary: "3000",
      active: true,
      created_at: "2022-07-21T07:05:21.346197+00:00",
      user_id: "0d80733e-a0d3-43f8-9ff8-6c02d64f2b04",
      monthly_net_salary: "36000",
      paygrade: null,
    },
    firstname: "July",
    lastname: "Adam",
    metadata: {
      phone: "+2349123526888",
    },
  },
  {
    __typename: "core_employee",
    payroll_info: {
      __typename: "core_payroll",
      metadata: {
        phone: "+2349123526889",
        staff_id: "2",
        bank_code: "GTB-176",
        account_number: "124676045",
        monthly_net_salary: "108000",
        monthly_gross_salary: "9000",
      },
      monthly_gross_salary: "9000",
      active: true,
      created_at: "2022-07-21T07:05:21.346197+00:00",
      user_id: "1a5ddf04-c616-417d-923c-7f9fb596cb4d",
      monthly_net_salary: "108000",
      paygrade: null,
    },
    firstname: "Tom",
    lastname: "Ade",
    metadata: {
      phone: "+2349123526889",
    },
  },
  {
    __typename: "core_employee",
    payroll_info: {
      __typename: "core_payroll",
      metadata: {
        phone: "+2349123526876",
        staff_id: "2",
        bank_code: "GTB-176",
        account_number: "124676089",
        monthly_net_salary: "208000",
        monthly_gross_salary: "19000",
      },
      monthly_gross_salary: "19000",
      active: true,
      created_at: "2022-07-21T07:05:21.346197+00:00",
      user_id: "1a5ddf04-c616-417d-923c-7f9fb596cb4d",
      monthly_net_salary: "208000",
      paygrade: null,
    },
    firstname: "Kyle",
    lastname: "Ford",
    metadata: {
      phone: "+2349123526876",
    },
  },
];
export const ReportData = [
  {
    data: {
      total_transaction: [
        {
          month: 11,
          year: 2022,
          total_transaction_volume: 1,
          total_transaction_value: 30000,
          avg_transaction_amount: 30000,
        },
      ],
      bill_payments: [
        {
          month: 11,
          year: 2022,
          total_transaction_volume: 1,
          total_transaction_value: 40000,
          avg_transaction_amount: 40000,
        },
      ],
      salary_advance: [
        {
          month: 11,
          year: 2022,
          total_transaction_volume: 1,
          total_transaction_value: 50000,
          avg_transaction_amount: 50000,
        },
      ],
      suceessful_transaction: {
        aggregate: {
          count: 1,
        },
      },
      failed_transaction: {
        aggregate: {
          count: 0,
        },
      },
    },
  },
];
export const ReportItemData = [
  {
    transactions: [
      {
        date: "2022-12-20T05:33:40.97197+00:00",
        amount: 4000,
        month: 12,
        transaction_id: 2,
        employee_name: "Emmanuel Adeola",
        status: "success",
        account_number: null,
      },
    ],
    bill_payment: [
      {
        date: "2022-11-23T19:31:41.065877+00:00",
        amount: 30000,
        month: 11,
        transaction_id: 1,
        employee_name: "Emmanuel Adeola",
        status: "success",
        account_number: "0124676074",
      },
    ],
    salary_advance_or_ewa: [
      {
        date: "2022-12-20T05:33:40.97197+00:00",
        amount: 4000,
        month: 12,
        transaction_id: 2,
        employee_name: "Emmanuel Adeola",
        status: "failed",
        account_number: null,
      },
    ],
  },
];

export const howItWorksData = [
  { counter: "1 ", note: "Create an account or log in" },
  { counter: "2 ", note: "Choose your preferred service Provider" },
  {
    counter: "3 ",
    note: "Enter your phone number select your preferred recharge plan.",
  },
  {
    counter: "4 ",
    note: " Complete the payment securely using available payment options",
  },
  {
    counter: "5 ",
    note: "Receive instant confirmation and enjoy uninterrupted services",
  },
];
export const BeneficiariesList = {
  success: true,
  data: {
    total: 9,
    limit: 20,
    skip: 0,
    data: [
      {
        sourceImage:
          "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
        uniqueNumber: "07065873900",
        nameAlias: "ola",
        metaData:
          '{"phoneNumber":"07065873900","amount":"30000","saveBeneficiary":true,"beneficiaryAlias":"ola","userPin":"1111","productId":1,"uniqueTransIdentity":"07065873900","availableBalance":103700}',
        product_list: {
          productName: "Data Plan",
          slug: "Data_Plan",
          image: "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo",
          provider: {
            productName: "MTN",
            slug: "mtn",
            image:
              "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
            id: 1,
          },
        },
      },
      {
        sourceImage:
          "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
        uniqueNumber: "07065873901",
        nameAlias: "james",
        metaData:
          '{"phoneNumber":"07065873901","amount":"30000","saveBeneficiary":true,"beneficiaryAlias":"james","userPin":"1111","productId":1,"uniqueTransIdentity":"07065873901","availableBalance":43700}',
        product_list: {
          productName: "Data Plan",
          slug: "Data_Plan",
          image: "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo",
          provider: {
            productName: "MTN",
            slug: "mtn",
            image:
              "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
            id: 1,
          },
        },
      },
      {
        sourceImage:
          "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
        uniqueNumber: "07065872901",
        nameAlias: "No Name",
        metaData:
          '{"phoneNumber":"07065872901","amount":"30000","saveBeneficiary":true,"userPin":"1111","productId":1,"uniqueTransIdentity":"07065872901","availableBalance":-46300}',
        product_list: {
          productName: "Data Plan",
          slug: "Data_Plan",
          image: "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo",
          provider: {
            productName: "MTN",
            slug: "mtn",
            image:
              "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
            id: 1,
          },
        },
      },
      {
        sourceImage:
          "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
        uniqueNumber: "07065872901",
        nameAlias: "No Name",
        metaData:
          '{"phoneNumber":"07065872901","amount":"30000","saveBeneficiary":true,"productId":2,"uniqueTransIdentity":"07065872901","availableBalance":-106300}',
        product_list: {
          productName: "Airtime",
          slug: "airtime",
          image: "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo",
          provider: {
            productName: "MTN",
            slug: "mtn",
            image:
              "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
            id: 1,
          },
        },
      },
      {
        sourceImage:
          "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
        uniqueNumber: "07065873900",
        nameAlias: "No Name",
        metaData:
          '{"phoneNumber":"07065873900","amount":"10000","fundSource":"self","dataCode":"100","name":"100MB Daily - Daily","productId":2,"saveBeneficiary":true,"uniqueTransIdentity":"07065873900","availableBalance":-136300}',
        product_list: {
          productName: "Airtime",
          slug: "airtime",
          image: "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo",
          provider: {
            productName: "MTN",
            slug: "mtn",
            image:
              "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
            id: 1,
          },
        },
      },
      {
        sourceImage:
          "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
        uniqueNumber: "09048596871",
        nameAlias: "No Name",
        metaData:
          '{"phoneNumber":"09048596871","amount":"2000000","saveBeneficiary":true,"productId":2,"scheduleBill":true,"frequency":"Daily","dayOfWeek":"Tuesday","dayOfMonth":2,"uniqueTransIdentity":"09048596871","availableBalance":3870000}',
        product_list: {
          productName: "Airtime",
          slug: "airtime",
          image: "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo",
          provider: {
            productName: "MTN",
            slug: "mtn",
            image:
              "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
            id: 1,
          },
        },
      },
      {
        sourceImage:
          "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
        uniqueNumber: "08094787465",
        nameAlias: "No Name",
        metaData:
          '{"phoneNumber":"08094787465","amount":"2000","saveBeneficiary":true,"productId":1,"scheduleBill":true,"frequency":"Daily","dayOfWeek":"Tuesday","dayOfMonth":2,"uniqueTransIdentity":"08094787465","availableBalance":3080000}',
        product_list: {
          productName: "Data Plan",
          slug: "Data_Plan",
          image: "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo",
          provider: {
            productName: "MTN",
            slug: "mtn",
            image:
              "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
            id: 1,
          },
        },
      },
      {
        sourceImage:
          "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
        uniqueNumber: "08094787465",
        nameAlias: "No Name",
        metaData:
          '{"phoneNumber":"08094787465","amount":"2000","saveBeneficiary":true,"productId":2,"scheduleBill":true,"frequency":"Daily","dayOfWeek":"Tuesday","dayOfMonth":2,"uniqueTransIdentity":"08094787465","availableBalance":92640300}',
        product_list: {
          productName: "Airtime",
          slug: "airtime",
          image: "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo",
          provider: {
            productName: "MTN",
            slug: "mtn",
            image:
              "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
            id: 1,
          },
        },
      },
      {
        sourceImage:
          "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
        uniqueNumber: "08090502267",
        nameAlias: "No Name",
        metaData:
          '{"phoneNumber":"08090502267","amount":"200000","saveBeneficiary":true,"productId":2,"scheduleBill":true,"frequency":"Daily","dayOfWeek":"Tuesday","dayOfMonth":2,"addToFavorite":true,"uniqueTransIdentity":"08090502267","availableBalance":88858300}',
        product_list: {
          productName: "Airtime",
          slug: "airtime",
          image: "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo",
          provider: {
            productName: "MTN",
            slug: "mtn",
            image:
              "https://seeklogo.com/images/M/MTN-logo-459AAF9482-seeklogo.com.png",
            id: 1,
          },
        },
      },
    ],
  },
  message: "   User saved Data Beneficiary",
};

export const AboutValues = [
  {
    image: "/images/ac.png",
    title: "Accessibility",
    content:
      "We believe in making connectivity accessible to all, ensuring that our services are user-friendly and available to everyone, regardless of technical expertise.",
  },
  {
    image: "/images/cv.png",
    title: "Convenience",
    content:
      "Our commitment is to provide seamless, hassle-free experiences,   offering convenience at every step of the recharge and bill payment process.",
  },
  {
    image: "/images/sc.png",
    title: "Security",
    content:
      "We prioritize the security of your personal and financial  information, implementing robust measures to safeguard your data   throughout every transaction.",
  },
  {
    image: "/images/cc.png",
    title: "Customer-Centricity",
    content:
      "Your satisfaction is our priority. We&#39;re committed to providing exceptional customer support and services that cater to your needs.",
  },
];
export const FaqDataList = [
  {
    title: "How can I Recharged airtime using Globb Recharge?",
    content:
      "Simply log in to the app, go to the Recharged section, select your preferred mobile network, enter the phone number, and choose the desired amount. Confirm the transaction to Recharged",
  },
  {
    title: "Can I purchase data bundles through Globb Recharge?",
    content:
      "Absolutely! Globb Recharge allows you to buy data bundles for various networks. Choose your network, navigate to the data section, and select the data bundle you need.",
  },
  {
    title: "How do I fund my wallet in the Globb Recharge app?",
    content:
      "To fund your wallet, on the homepage, click on 'Add Funds,' choose your payment method, and follow the prompts to add money to your Globb Recharge wallet.",
  },
  {
    title:
      "Is it possible to transfer funds to another user through Globb Recharge?",
    content:
      "Yes, you can transfer funds to another beneficiary within the Globb Recharge app. Access the transfer section, enter the recipient's details, and complete the transfer.",
  },
  {
    title: "How can I earn through the referral program?",
    content:
      "Invite friends to use Globb Recharge by sharing your referral code. When they make their first Recharged or fund their wallet, you earn rewards as part of our referral program.",
  },
  {
    title: "Are there rewards or offers available in Globb Recharge?",
    content:
      " Absolutely! Keep an eye out for promotions, cashback offers, and rewards that may be available. These can be in the form of coupons or direct cash back to your wallet.",
  },
  {
    title:
      "Can I save my favorite Recharged and beneficiaries for quick access?",
    content:
      "Yes, Globb Recharge allows you to save your frequently used Recharged and beneficiaries. This makes it convenient for you to initiate transactions with just a few clicks.",
  },
  {
    title: "How can I schedule my Recharged in the Globb Recharge app?",
    content:
      "Simply input your phone number and Recharged amount, then schedule your Recharged for a convenient date. Rest assured, you'll receive the transaction value on your chosen date and instantly.",
  },
  {
    title:
      "Is there a customer support service available in case I encounter issues?",
    content:
      "Certainly! If you have any issues or questions, reach out to our customer support team through the app. We're here to assist you with any concerns you may have.",
  },
  {
    title: "Can I check my transaction history in Globb Recharge?",
    content:
      "Yes, you can view your transaction history in the app. Simply go to the transaction history section, and you'll find a record of all your previous transactions",
  },
];
export const TermsAndConditionDataList = [
  {
    title: "1. INTRODUCTION",
    text1:
      "1.1 The Globb Recharge app, hereinafter referred to as 'the App,' is a platform designed to facilitate the purchase of airtime and data bundles, fund your wallet, transfer credits to other beneficiaries, earn rewards through referrals, and enjoy offers via coupons or cashback.",

    text2:
      "1.2 By using the App, you agree to comply with and be bound by these Terms and Conditions.",
  },
  {
    title: "2. Account Registration",
    text1:
      "2.1 To use certain features of the App, you may be required to register for an account. You must provide accurate and complete information during the registration process",

    text2:
      "2.2 You are responsible for maintaining the confidentiality of your account login credentials. Any activity occurring under your account is your responsibility.",
  },
  {
    title: "3. Services and Transactions",
    text1:
      "3.1 Globb Recharge allows users to purchase airtime and data bundles, fund their wallet, and transfer credits to other beneficiaries seamlessly.",

    text2:
      "3.2 The App reserves the right to modify, suspend, or discontinue any part of the services at any time.",
  },
  {
    title: "4. Referral Program",
    text1:
      "4.1 Users can earn rewards by referring others to use the Globb Recharge app. Referral rewards will be subject to terms specified by the App.",
  },
  {
    title: "5. Coupons and Cashback",
    text1:
      "5.1 The App may offer coupons or cashback rewards as part of promotional activities. Users are encouraged to review the terms and conditions associated with each offer.",
  },
  {
    title: "6. Wallet and Transactions",
    text1:
      "6.1 Users can load funds into their wallet for convenient transactions within the App.",

    text2:
      "6.2 All transactions made through the App are final, and refunds may only be issued in accordance with the App's refund policy.",
  },
  {
    title: "7. Privacy Policy",
    text1:
      "7.1 Your use of the App is also governed by our Privacy Policy, which can be found here.",
  },
  {
    title: "8. User Conduct",
    text1:
      "8.1 Users are expected to use the App in compliance with all applicable laws and regulations.",

    text2:
      "8.2 Any fraudulent or suspicious activities will result in the immediate termination of the user's account.",
  },
  {
    title: "9. Modification of Terms",
    text1:
      "9.1 Globb Recharge reserves the right to modify these Terms and Conditions at any time. Users will be notified of such changes through the App or via email.",

    text2:
      "9.2 Continued use of the App after any modification constitutes acceptance of the updated Terms and Conditions.",
  },
  {
    title: "10. Termination",
    text1:
      "10.1 Globb Recharge may terminate or suspend your account without prior notice if it believes you have violated these Terms and Conditions.",
  },
  {
    title: "11. Contact Information",
    text1:
      "11.1 For any questions or concerns regarding these Terms and Conditions, please contact us at [info@globbtech.com].",
  },
];
export const PolicyDataList = [
  {
    type: 'single',
    title: "1. INTRODUCTION",
    text1:
      "Globb Recharge provides seamless mobile phone recharge and data purchase services while upholding the highest standards of privacy protection.",
  },
  {type: 'multipleList',
    title: "2. INFORMATION COLLECTION",
    title2: "2.1 Personal Information",
    minText:
      "We meticulously collect and store the following categories of personal information:",
    list: [
      {
        txt1: "Contact Information",
        txt2: "Including but not limited to names, email addresses, and phone numbers.",
      },
      {
        txt1: "Usage Data:",
        txt2: "Sensitive data such as credit card details and billing addresses.",
      },
      {
        txt1: "Contact Information",
        txt2: "Comprising transaction history and user preferences.",
      },
    ],
  },
  {
    type: 'single',
    title: "2.2 Non-Personal Information",
    text1:
      "In addition, we may gather non-personal data encompassing device specifics, operating systems, and browser types.",
  },
  {
    type: 'singleListed',
    title: "3. PURPOSE OF DATA USAGE",
    minText:
      "We employ your personal information for the following explicit purposes:",
    list: [
      "Providing Products and Services to you",
      "Maintaining, supporting, evaluating, improving and/or developing our Products and Services and developing new Products and Services",
      "Managing your relationship with us.",
      "Provide targeted marketing and advertising.",
      "Contact you at any telephone number, by placing a voice call or through text (SMS) or email messaging",
      "Enforcing our rights under the Agreement, and as permitted with the Authorized User’s consent solely with respect to their own Personal Information.",
      "Facilitating seamless recharge and data purchase transactions.",
      "Ensuring timely and effective response to user inquiries.",
      "Disseminating pertinent notifications and updates.",
      "Iterative refinement of our offerings for an improved user experience.",
      "Fraud Detection and Usage Monitoring: Safeguarding against fraudulent activities and monitoring service utilization patterns.",
    ],
  },
  {
    type: 'multipleList',
    title: "4. INFORMATION SHARING",
    title2: "",
    minText:
      "Your information may be disclosed to:",
    list: [
      {
        txt1: "Service Providers:",
        txt2: "Trusted third-party service providers who assist us in delivering our services, bound by stringent confidentiality agreements.",
      },
      {
        txt1: "Legal Mandates:",
        txt2: " Compliance with applicable laws, regulations, or legal processes.",
      },
      {
        txt1: "Business Transitions: ",
        txt2: "In the event of mergers, acquisitions, or sale of assets, pertinent information may be shared as part of the transition process.",
      },
    ],
  },
  {
    type: 'single',
    title: "5. SECURITY MEASURES",
    text1:
      "We have instituted rigorous security measures to protect your personal information from unauthorized access, alteration, or disclosure.We have instituted rigorous security measures to protect your personal information from unauthorized access, alteration, or disclosure.",

    text2:
      "",
  },
  {
    type: 'single',
    title: "6. COOKIES AND TRACKING TECHNOLOGIES",
    text1:
      "Cookies and akin tracking technologies are utilized to enhance your interaction with our platform. Browser settings can be adjusted to disable cookies.",
  },
  {
    type: 'single',
    title: "7. THIRD-PARTY LINKS7. THIRD-PARTY LINKS",
    text1:
      "Our platform may feature links to third-party websites or services. We disclaim responsibility for their privacy practices and strongly recommend a thorough review of their respective policies.",
  },
  {
    type: 'single',
    title: "8. AMENDMENTS TO THIS PRIVACY POLICY",
    text1:
      "This Privacy Policy may be periodically updated. Any alterations will be prominently displayed on our platform, along with the date of the latest revision.",

    text2:
      "",
  },
  
];
