import React from "react";
import { Text } from "components";
export type LineProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    note: string;
    counter: string;
  }>;

const FlowFeed: React.FC<React.PropsWithChildren<LineProps>> = ({
  note,
  counter,
  ...restProps
}) => {
  return (
    <div
      className="flex flex-row gap-[42px] items-center justify-start w-full"
      {...restProps}
    >
      <div className="bg-deep_orange-500 flex  items-center justify-center sm:px-5 rounded-[50%] h-[60px]  w-[60px] min-w-[60px] min-h-[60px] sm:h-[40px]  sm:w-[40px] sm:min-w-[40px] sm:min-h-[40px]">
        <Text
          className="md:text-3xl sm:text-[20px] text-[32px] text-center text-white-A700"
          size="txtInterBold32"
        >
          {counter}
        </Text>
      </div>
      <Text
        className="text-2xl md:text-[22px] text-black-900 sm:text-[16px]"
        size="txtInterBold24"
      >
        {note}
      </Text>
    </div>
  );
};
export { FlowFeed };
