import React from "react";

const shapes = {
  square: "rounded-none",
  round: "rounded-[5px]",
  circle: "rounded-[50%]",
} as const;
const variants = {
  fill: {
    white_A700: "bg-white-A700",
    deep_orange_500: "bg-deep_orange-500 text-white-A700",
    light_blue_A700: "bg-light_blue-A700",
    amber_A400_19: "bg-amber-A400_19 text-orange-700",
    red_A700_19: "bg-red-A700_19 text-red-A700",
    light_green_900_19: "bg-light_green-900_19 text-light_green-900",
    light_green_900: "bg-light_green-900 text-white-A700",
    blue_gray_100: "bg-blue_gray-100",
  },
  outline: {
    light_green_900:
      "border border-light_green-900 border-solid text-light_green-900",
    deep_orange_500:
      "border-2 border-deep_orange-500 border-solid text-deep_orange-500",
  },
} as const;
const sizes = {xxs: "p-[3px]", xs: "p-[11px]", sm: "p-[14.5px]", md: "p-[15px]", lg: "p-5" } as const;

export type ButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "onClick"
> &
  Partial<{
    className: string;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
    loading: boolean;
    disabled: boolean;
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    onClick: () => void;
  }>;

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  loading = false,
  disabled = false,
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${disabled ? "cursor-not-allowed font-bold font-inter mt-5 w-full text-base text-center" : `hover:bg-opacity-80  ${className}`} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]?.[color]) || ""}`}
      disabled={disabled}
      {...restProps}
    >
      {loading ? (
        "loading..."
      ) : (
        <>
          {!!leftIcon && leftIcon}
          {children}
          {!!rightIcon && rightIcon}
        </>
      )}
    </button>
  );
};

export { Button };
