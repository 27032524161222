import React from "react";

const sizeClasses = {
  txtMontserratRomanRegular16Black900: "font-montserrat font-normal",
  txtMontserratRomanRegular14RedA700: "font-montserrat font-normal",
  txtMontserratRomanBold24: "font-bold font-montserrat",
  txtPoppinsBold32: "font-bold font-poppins",
  txtMontserratRomanRegular14: "font-montserrat font-normal",
  txtMontserratRomanBold20: "font-bold font-montserrat",
  txtInterBold2024: "font-bold font-inter",
  txtMontserratRomanRegular12: "font-montserrat font-normal",
  txtInterBold24: "font-bold font-inter",
  txtMontserratRomanBold36Black900: "font-bold font-montserrat",
  txtMontserratRomanBold1775: "font-bold font-montserrat",
  txtInterBold20: "font-bold font-inter",
  txtMontserratRomanRegular16RedA700: "font-montserrat font-normal",
  txtMontserratRomanRegular16Gray50003: "font-montserrat font-normal",
  txtMontserratRomanRegular1906: "font-montserrat font-normal",
  txtMontserratRomanRegular16Gray400: "font-montserrat font-normal",
  txtInterBlack2024: "font-black font-inter",
  txtMontserratRomanBold16Lightgreen900: "font-bold font-montserrat",
  txtInterBold24WhiteA700: "font-bold font-inter",
  txtMontserratRomanBold16: "font-bold font-montserrat",
  txtMontserratRomanBold12: "font-bold font-montserrat",
  txtMontserratRomanBold24Deeporange500: "font-bold font-montserrat",
  txtInterBold16: "font-bold font-inter",
  txtMontserratRomanBold16WhiteA700: "font-bold font-montserrat",
  txtInterBold16Black900: "font-bold font-inter",
  txtMontserratRomanRegular24: "font-montserrat font-normal",
  txtMontserratRomanRegular16: "font-montserrat font-normal",
  txtMontserratRomanMedium20: "font-medium font-montserrat",
  txtInterRegular14: "font-inter font-normal",
  txtInterRegular16: "font-inter font-normal",
  txtInterBold16Lightgreen900: "font-bold font-inter",
  txtMontserratRomanRegular16WhiteA700: "font-montserrat font-normal",
  txtInterBlack32: "font-black font-inter",
  txtInterRegular16Gray50003: "font-inter font-normal",
  txtInterRegular2024: "font-inter font-normal",
  txtMontserratRomanSemiBold142: "font-montserrat font-semibold",
  txtMontserratRomanBold36: "font-bold font-montserrat",
  txtInterRegular16WhiteA700: "font-inter font-normal",
  txtInterBold36: "font-bold font-inter",
  txtMontserratRomanBold1906: "font-bold font-montserrat",
  txtInterBold1346: "font-bold font-inter",
  txtInterBold1863: "font-bold font-inter",
  txtMontserratRomanBold32: "font-bold font-montserrat",
  txtMontserratRomanBold16RedA700: "font-bold font-montserrat",
  txtInterBold32: "font-bold font-inter",
  txtMontserratRomanRegular16Deeporange500: "font-montserrat font-normal",
  txtInterBold32Black900: "font-bold font-inter",
  txtMontserratRomanBold32WhiteA700: "font-bold font-montserrat",
  txtMontserratRomanRegular14Lightgreen900: "font-montserrat font-normal",
  txtMontserratRomanRegular16Lightgreen900: "font-montserrat font-normal",
} as const;

export type TextProps = Partial<{
  className: string;
  size: keyof typeof sizeClasses;
  as: React.ElementType;
}> &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  size,
  as = 'p',
  ...restProps
}) => {
  const TextTag = as;

  return (
    <TextTag
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </TextTag>
  );
};

export { Text };
