import React, {
  forwardRef,
  ForwardedRef,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
} from "react";
import { ErrorMessage } from "../../components/ErrorMessage";

const shapes = { round: "rounded-[5px]" } as const;
const variants = {
  outline: { gray_400: "border border-gray-400 border-solid text-gray-400" },
  underline: { black_900: "border-b border-black-900 text-black-900" },
  fill: { white_A700_4c: "bg-white-A700_4c text-white-A700" },
} as const;
const sizes = {
  xs: "pb-2 pt-[11px] px-2",
  sm: "p-[13px]",
  md: "pb-[19px] pt-5 px-[19px]",
} as const;

type InputRef = ForwardedRef<HTMLInputElement>;
type TextareaRef = ForwardedRef<HTMLTextAreaElement>;

export type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "size" | "prefix" | "type" | "onChange" | "ref"
> &
  Partial<{
    wrapClassName: string;
    className: string;
    name: string;
    placeholder: string;
    type: string;
    errors: any;
    label: string;
    prefix: React.ReactNode;
    suffix: React.ReactNode;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
  }> & { ref?: InputRef };

export type TextareaProps = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  "size" | "prefix" | "type" | "onChange" | "ref"
> &
  Partial<{
    wrapClassName: string;
    className: string;
    name: string;
    placeholder: string;
    type: string;
    errors: any;
    label: string;
    prefix: React.ReactNode;
    suffix: React.ReactNode;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
  }> & { ref?: TextareaRef };

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      wrapClassName = "",
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      children,
      errors = "",
      label = "",
      prefix,
      suffix,
      onChange,
      shape = "",
      size = "sm",
      variant = "underline",
      color = "black_900",
      ...restProps
    },
    ref
  ) => {
    return (
      <>
        <div
          className={`${wrapClassName} 
              ${(shape && shapes[shape]) || ""} 
              ${(variant && variants[variant]?.[color]) || ""} 
              ${(size && sizes[size]) || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <input
            ref={ref}
            className={`${className} bg-transparent border-0`}
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  }
);

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({
    wrapClassName = "",
    className = "",
    name = "",
    placeholder = "",
    type = "text",
    children,
    errors = "",
    label = "",
    prefix,
    suffix,
    onChange,
    shape = "",
    size = "sm",
    variant = "underline",
    color = "black_900",
    ref,
    ...restProps
  }) => {
    return (
      <>
        <div
          className={`${wrapClassName} 
              ${(shape && shapes[shape]) || ""} 
              ${(variant && variants[variant]?.[color]) || ""} 
              ${(size && sizes[size]) || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <textarea
            ref={ref as TextareaRef}
            className={`${className} bg-transparent border-0`}
            name={name}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  }
);

export { Input, Textarea };
