import React from "react";

export const Loader = ({ count, addClasses="max-w-[50%]" }) => {
  const loaders = [];
  for (let i = 0; i < count; i++) {
    loaders.push(
      <div key={i} role="status" className={`${addClasses} max-w-[50%] min-w-[22%] animate-pulse`}>
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return <div className="w-full max-w-[1041px]  mx-auto flex gap-[11px] items-center justify-between px-3 py-10 flex-wrap">{loaders}</div>;
};
