import React, {InputHTMLAttributes} from "react";
import { Button, Img, List, Text, Line } from "components";

export type InputProps = Partial<{
  ImageLink?: string;
  name?: string;
  placeholder?: string;
  className?: any;
  ref?: any;
  onChange?: any;
  onClick?: any;
  defaultValue?: any;
  showViewPassword?: boolean;
  passwordIcon?: string;
  type?: string;
}>& InputHTMLAttributes<HTMLInputElement>;
export const InputText: React.FC<InputProps> = ({
  ImageLink = "",
  name,
  placeholder = "",
  passwordIcon = "images/Eye.png",
  className,
  ref,
  onChange,
  defaultValue,
  onClick,
  showViewPassword =  false,
  type,
  ...props
}) => {
  return (
    <div className="bg-light_green-900_0c border border-[#B3B3B3] border-solid  rounded-[5px] w-full md:w-full">
      <div className="flex flex-row gap-5 sm:gap-2 items-center justify-start w-full h-[44px] px-4">
        {ImageLink && (
          <div className="flex h-[34px] items-center justify-center rounded-[50%] w-[34px] bg-[#fff] sm:hidden">
            <Img className="h-6" src={ImageLink} alt="frame321" />
          </div>
        )}
        <input
          className={`text-base text-gray-400 bg-[transparent] flex-1 ${className} border-0 min-w-[110px]`}
          defaultValue={defaultValue}
          name={name}
          placeholder={placeholder}
          ref={ref}
          onChange={onChange}
          type={type}
          // value={value}

          {...props}
        />
        {showViewPassword&& (
          <div className="flex h-[34px] items-center justify-center rounded-[50%] w-[34px] bg-[#fff] cursor-pointer">
            <Img onClick={onClick} className="h-[24px] w-[24px]" src={passwordIcon} alt="frame321" />
          </div>
        )}
      </div>
    </div>
  );
};
