import React from "react";
import Routes from "./Routes";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
     
      <Toaster />
      <Routes />
    </>
  );
}

export default App;
