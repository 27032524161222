import React from "react";
import { Disclosure } from "@headlessui/react";
import { FaqDataList } from "components/util/dataCenter";

export type ImgProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> &
  Partial<{
    className: string;
    src: string;
    alt: string;
  }>;

const Faq: React.FC<React.PropsWithChildren<ImgProps>> = ({
  className,
  src = "defaultNoData.png",
  alt = "testImg",
  ...restProps
}) => {
  return (
    <div className="w-full px-0 pt-0">
      <div className="mx-auto w-full sm:flex-col rounded-2xl bg-white  flex  gap-[5px] flex-wrap justify-between">
        {FaqDataList?.map((items, index) => (
          <div className="w-[48%] min-w-[45%] sm:w-[100%]">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg py-2 text-left text-md font-medium text-[#000000]  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                    <span className={`${open ? "text-[#ff6b18]" : ""}  `}>
                      {items?.title}
                    </span>
                    <img
                      src="/images/plus.svg"
                      className={`${
                        open ? "rotate-180 transform hidden" : ""
                      }  text-purple-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                    {items?.content}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </div>
  );
};
export { Faq };
