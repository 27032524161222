import React from "react";
import { Img } from "components";

export type LineProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    children: any;
    passwordValue?: string;
  }>;
const containsMinimumLength = (value: string) => value?.length >= 8;
const containsUpperCase = (value: string) => /[A-Z]/.test(value);
const containsNumber = (value: string) => /\d/.test(value);
const containsSpecialCharacter = (value: string) =>
  /[!@#$%^&*(),.?":{}|<>]/.test(value);
const PasswordTooltip: React.FC<React.PropsWithChildren<LineProps>> = ({
  children,
  passwordValue,
}) => {
  const isMinimumLength = containsMinimumLength(passwordValue);
  const hasUpperCase = containsUpperCase(passwordValue);
  const hasNumber = containsNumber(passwordValue);
  const hasSpecialCharacter = containsSpecialCharacter(passwordValue);
  return (
    <div className="group relative flex flex-col">
      {children}
      <span className="absolute min-w-[220px]  top-[80px] scale-0 transition-all rounded bg-[#e5e7eb] p-2 text-xs flex items-start text-white group-hover:scale-100">
        <Img
          className="h-5 md:h-auto mr-1 object-cover w-5"
          src="images/img_brakewarning.png"
          alt="brakewarning"
        />
        <ul className="list-outside">
          <li
            className={`${!isMinimumLength ? "text-[#000]" : "text-[#ff6b18]"}`}
          >
            Minimum of 8 Character
          </li>
          {
            <li
              className={`${!hasUpperCase ? "text-[#000]" : "text-[#ff6b18]"}`}
            >
              Upper case
            </li>
          }
          {
            <li className={`${!hasNumber ? "text-[#000]" : "text-[#ff6b18]"}`}>
              Number
            </li>
          }
          {
            <li
              className={`${
                !hasSpecialCharacter ? "text-[#000]" : "text-[#ff6b18]"
              }`}
            >
              Special Character
            </li>
          }
        </ul>
      </span>
    </div>
  );
};
export { PasswordTooltip };