import React from "react";

export type BoxProps = Partial<{
  Image: string;
  text1: string;
  text2: string;
  statusImage: string;
  onClick: any;
  isActivate: boolean;
}>;
export const Box: React.FC<BoxProps> = ({
  Image = "",
  text1 = "",
  text2 = "",
  statusImage = "user.png",
  onClick,
  isActivate,
}) => {
  return (
    <div className="w-[100%] h-[69px] mt-[27px]" onClick={onClick}>
      {/* <div className="w-[100%] h-[69px]"> */}
      <div
        className="relative w-[100%] min-w-[100%] h-[69px]  bg-[#476c2c] rounded-[5px] shadow-[4px_4px_15px_#0000001a] flex 
        items-center px-[10px] cursor-pointer"
      >
        <div className="p-[10px] bg-[#ff6b18] rounded-[30px] inline-flex items-start mr-[10px]">
          <img
            className="relative w-[24px] h-[24px]"
            alt="User"
            src={statusImage}
          />
        </div>
        <div className="flex flex-col items-start gap-[5px]  w-[145px] h-[39px] top-[17px] left-[80px]">
          <p className="relative self-stretch [font-family:'Inter-Regular',Helvetica] font-normal text-white text-[14px] tracking-[0] leading-[normal]">
            <span className="[font-family:'Inter-Regular',Helvetica] font-normal text-white text-[14px] tracking-[0] text-sky-100">
              {text1}
              <br />
            </span>
          </p>
          <p className="relative self-stretch [font-family:'Inter-Regular',Helvetica] font-normal text-white text-[14px] tracking-[0] leading-[normal]">
            <span className="[font-family:'Inter-Bold',Helvetica] font-bold text-sky-100">
              {text2}
              {/* xxxxxxxxxxxxxxx */}
            </span>
          </p>
        </div>
        <div className="top-[29px] right-[30px] inline-flex items-start gap-[10px] absolute">
          {isActivate ? (
            <img
              className="relative w-[15px] h-[15px]"
              alt="User"
              src={"/images/active.svg"}
            />
          ) : (
            <img
              className="relative w-[15px] h-[15px]"
              alt="User"
              src={"/images/inActive.svg"}
            />
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
// export const ErrorMessage: React.FC<Props> = ({
//   errors = "",
//   className = "",
// }) => {
//   return errors?.length > 0 ? (
//     <div className={`text-red-500 text-left text-xs w-full mt-1 ${className}`}>
//       {errors}
//     </div>
//   ) : (
//     <></>
//   );
// };
