import React from "react";
import { Text } from "components";

export type BoxProps = Partial<{
  className: any;
  onClick: any;
}>;
export const EmptyState: React.FC<BoxProps> = ({ className, onClick }) => {
  return (
    <div
      className={`w-[100%] max-w-[300px] rounded-[10px] flex flex-col items-center justify-center bg-[#f4f4f4] gap-2.5 p-[10px] ${className}`}
    >
      <Text
        className="text-2xl md:text-[22px]"
        size="txtMontserratRomanRegular24"
      >
        No Data Found
      </Text>

      <Text
        className="text-[14px] text-deep_orange-500 cursor-pointer"
        onClick={onClick}
        size="txtMontserratRomanRegular24"
      >
        Click to try again
      </Text>
    </div>
  );
};
