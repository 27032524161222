import React from "react";
import { Img } from "components";

export type LineProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    children: any;
    message: string;
  }>;

const Tooltip: React.FC<React.PropsWithChildren<LineProps>> = ({
  children,
  message,
}) => {
  return (
    <div className="group relative flex">
      {children}
      <span className="absolute min-w-[220px] top-10 scale-0 transition-all rounded bg-[#e5e7eb] p-2 text-xs flex items-start text-white group-hover:scale-100">
        <Img
          className="h-5 md:h-auto mr-1 object-cover w-5"
          src="images/img_brakewarning.png"
          alt="brakewarning"
        />
        {message}
      </span>
    </div>
  );
};
export { Tooltip };
